import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/gmobile/dktt', state: 'gmobileDkttMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }

  render() {
    return (
      <nav className="bottom-navbar" id="sidebar">
        <div className="container">
          <ul className="nav page-navigation">
            <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'} onClick={() => document.getElementById("sidebar").classList.remove('header-toggled')} >
              <Link className="nav-link" to="/dashboard">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title"><Trans>Trang chủ</Trans></span>
              </Link>
            </li>
            <li onClick={(e) => e.currentTarget.classList.toggle('show-submenu')} className={this.isPathActive('/gmobile/dktt') ? 'nav-item active ' : 'nav-item'}>
              <div className="nav-link"><i className="mdi mdi-webpack menu-icon"></i><span
                className="menu-title"><Trans>GMOBILE DKTT</Trans></span><i className="menu-arrow"></i></div>
              <div className="submenu">
                <ul className="submenu-item" onClick={() => document.getElementById("sidebar").classList.remove('header-toggled')} >
                  <li className="nav-item"> <Link className={this.isPathActive('/gmobile/dktt/huong-dan') ? 'nav-link active' : 'nav-link'} to="/gmobile/dktt/huong-dan"><Trans>Hướng dẫn sử dụng</Trans></Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/gmobile/dktt/order') ? 'nav-link active' : 'nav-link'} to="/gmobile/dktt/order"><Trans>Quản lý đơn</Trans></Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/gmobile/dktt/create') ? 'nav-link active' : 'nav-link'} to="/gmobile/dktt/create"><Trans>Khởi tạo</Trans></Link></li>
                  <li className="nav-item"> <Link className={this.isPathActive('/gmobile/dktt/list') ? 'nav-link active' : 'nav-link'} to="/gmobile/dktt/list"><Trans>Lịch sử giao dịch</Trans></Link></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }
}

export default withRouter(Sidebar);