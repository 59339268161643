import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
import ProtectedRoute from '../Route/ProtectedRoute';
import ChangePasswordProfile from './profile/ChangePass';

const DashboardDaunoi = lazy(() => import('./dashboard/DashboardDaunoi'));
const Mdi = lazy(() => import('./icons/Mdi'));
const OrderList = lazy(() => import('./order/List'));
const Login = lazy(() => import('./user-pages/Login'));
const InfoProfile = lazy(() => import('./profile/Info'));
const BanggiaList = lazy(() => import('./banggia/List'));
const ThongbaoList = lazy(() => import('./thongbao/List'));


const AddFriendCreate = lazy(() => import('./add-friend/Create'));
const AddFriendList = lazy(() => import('./add-friend/List'));
const AddFriendHuongDan = lazy(() => import('./add-friend/Huongdan'));

const PermissionCreate = lazy(() => import('./permission/Create'));
const PermissionList = lazy(() => import('./permission/List'));

const SoduList = lazy(() => import('./sodu/List'));
const PackageBuyList = lazy(() => import('./package-buy/List'));


const GmobileDkttCreate = lazy(() => import('./gmobile-dktt/Create'));
const GmobileDkttList = lazy(() => import('./gmobile-dktt/List'));
const GmobileDkttHuongDan = lazy(() => import('./gmobile-dktt/Huongdan'));



class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <ProtectedRoute exact path="/dashboard" component={DashboardDaunoi} />
          <ProtectedRoute path="/profile" component={InfoProfile} />
          <ProtectedRoute path="/change-password" component={ChangePasswordProfile} />
          <ProtectedRoute path="/add-friend/create" component={AddFriendCreate} />
          <ProtectedRoute path="/add-friend/list" component={AddFriendList} />
          <ProtectedRoute path="/add-friend/huong-dan" component={AddFriendHuongDan} />

          <ProtectedRoute path="/permission/create" component={PermissionCreate} />
          <ProtectedRoute path="/permission/list" component={PermissionList} />

          <ProtectedRoute path="/bang-gia" component={BanggiaList} />
          <ProtectedRoute path="/so-du" component={SoduList} />
          <ProtectedRoute path="/thongbao/tatca" component={ThongbaoList} />
          <ProtectedRoute path="/qty-list" component={PackageBuyList} />
          <ProtectedRoute path="/gmobile/dktt/create" component={GmobileDkttCreate} />
          <ProtectedRoute path="/gmobile/dktt/huong-dan" component={GmobileDkttHuongDan} />
          <ProtectedRoute path="/gmobile/dktt/list" component={GmobileDkttList} />
          <ProtectedRoute path="/gmobile/dktt/order" component={OrderList} />
          <Route path="/dang-nhap" component={Login} />
          <Route path="/icons/mdi" component={Mdi} />
          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;