import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  allUsersReducer,
  profileReducer,
  userDetailsReducer,
  userReducer,
} from "./reducers/userReducer";

import { addFriendReducer } from "./reducers/addFriendReducer";
import { banggiaReducer } from "./reducers/banggiaReducer";
import { thongbaoReducer } from "./reducers/thongbaoReducer";
import { soduReducer } from "./reducers/soduReducer";
import { provinceReducer } from "./reducers/provinceReducer";
import { packageBuyReducer } from "./reducers/packagebuyReducer";
import { gmobileReducer } from "./reducers/gmobileReducer";
import { orderReducer } from "./reducers/orderReducer";
import { permissionReducer } from "./reducers/permissionReducer";


const reducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  banggia: banggiaReducer,
  thongbao: thongbaoReducer,
  sodu: soduReducer,
  friend: addFriendReducer,
  province: provinceReducer,
  packageBuy: packageBuyReducer,
  gmobile: gmobileReducer,
  order: orderReducer,
  permission: permissionReducer,
});




let initialState = {

};

const middleware = [thunk];

// const store = createStore(
//   reducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

const devTools =
  process.env.NODE_ENV !== "production"
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware));
const store = createStore(reducer, initialState, devTools);
export default store;
