import React, { useState, useEffect, Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, updatePassword } from '../../actions/userAction';
import { useAlert } from 'react-alert'
import  Loader  from '../Loader/Loader'

const ChangePasswordProfile = ({ history, location }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { user, error,loading,success } = useSelector((state) => state.user);
  const [password, setProfilePassword] = useState("");
  const [passwordOld, setProfilePasswordOld] = useState("");
  const [passwordRe, setProfilePasswordRe] = useState("");
  const [errorField, setErrorField] = useState({});
  const profileChangePasswordSubmit = (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.set('password',password );
    myForm.set('password_re',passwordRe );
    myForm.set('password_old',passwordOld );
    let errorSubmit = {};
    if (!password){
      errorSubmit['password'] = "* Vui lòng điền mật khẩu mới";
    }
    if (!passwordRe){
      errorSubmit['passwordre'] = "* Vui lòng điền mật khẩu nhập lại";
    }
    if (!passwordOld){
      errorSubmit['passwordold'] = "* Vui lòng điền mật khẩu cũ";
    }
    console.log(errorSubmit);
    setErrorField(errorSubmit);
    if (Object.keys(errorSubmit).length === 0){
      dispatch(updatePassword(myForm));
      setErrorField({});
    }
  };
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success(success);
      dispatch(clearErrors());
      setProfilePasswordOld("");
      setProfilePasswordRe("");
      setProfilePassword("");
    }
  }, [dispatch, user, history, loading,success,error,alert]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
    <div>
      {/* <div className="page-header">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="!#">Tài khoản</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">Đổi mật khẩu</li>
          </ol>
        </nav>
      </div> */}
      <div className="row" >
          <div className="col-md-8 grid-margin stretch-card" >
            <div className="card" >
              <div className="card-body" >
                <h4 className='card-title'> Đổi mật khẩu</h4>
                <Form className="pt-3" onSubmit={profileChangePasswordSubmit}>
                <label >Mật khẩu cũ</label>
                <Form.Group className=" search-field">
                  <Form.Control type="password" value={passwordOld || ''} onChange={(e) => setProfilePasswordOld(e.target.value)} placeholder="Mật khẩu cũ" name='password' size="lg" className="h-auto" />
                  <span className='text-danger'>{errorField['passwordold']}</span>
                </Form.Group>
                <label >Mật khẩu mới</label>
                <Form.Group className="search-field">
                  <Form.Control type="password" value={password || ''} onChange={(e) => setProfilePassword(e.target.value)} placeholder="Mật khẩu mới" name='password' size="lg" className="h-auto" />
                  <span className='text-danger'>{errorField['password']}</span>
                </Form.Group>
                <label >Nhập lại mật khẩu mới</label>
                <Form.Group className=" search-field">
                  <Form.Control type="password" value={passwordRe || ''} onChange={(e) => setProfilePasswordRe(e.target.value)} placeholder="Nhập lại mật khẩu mới" name='passwordre' size="lg" className="h-auto" />
                  <span className='text-danger'>{errorField['passwordre']}</span>
                </Form.Group>
                <div className="mt-3">
                  <button type='submit' className="btn btn-block btn-primary font-weight-medium">Cập nhật</button>
                </div>
              </Form>
              </div>
            </div>
          </div>
        </div>
    </div>
     )}
     </Fragment>
   );
 };

export default ChangePasswordProfile;
