import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { loadUser } from "../actions/userAction";
import { getThongbaoMoinhat } from "../actions/ThongbaoAction";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const user = localStorage.getItem('access_token')
  const dispatch = useDispatch();
  return (
    <Fragment>
      {(
        <Route
          {...rest}
          render={(props) => {
            if (user === undefined || !user) {
              return <Redirect to="/dang-nhap" />;
            }
            if (props.location.pathname === '/dang-nhap') {
              return <Redirect to="/" />;
            }
            dispatch(getThongbaoMoinhat());
            dispatch(loadUser());

            return <Component {...props} />;
          }}
        />
      )}
    </Fragment>
  );
};

export default ProtectedRoute;
